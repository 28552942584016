import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMutation } from "react-query";
import { Form, Container, Alert, Button, Row, Col, Card } from "react-bootstrap";

import { useAuth } from "../../providers/AuthContext";
import { POST } from "../../components/Constants";
import Loader from "../../components/Loader";

const ChangePassword = () => {
    const initalData = {
        oldPassword: "",
        choosePassword: "",
        confirmPassword: ""
    };

    const auth = useAuth();
    const [user, setUser] = useState(initalData);
    const update = useMutation((data) => POST("users/change-password", auth, data));;

    const handleChange = (e) => {
        const { id, value } = e.target;
        setUser((ps) => ({
            ...ps,
            [id]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        update.mutate(user, { onSuccess: () => setUser(initalData) });
    };

    const isValidPassword = (password) => {
        // Regex pattern that checks:
        // - At least one uppercase letter
        // - At least one special character
        // - Length between 8 and 64 characters
        const pattern = /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,64}$/;

        return pattern.test(password);
    }

    return (
        <>
            <Helmet title="Change Password" />
            {update.isLoading && <Loader />}
            <Container style={{ paddingTop: 10 + 'rem', paddingBottom: 10 + 'rem' }}>
                <Row className="justify-content-md-center">
                    <Col md="10" lg="8">
                        <Card className="p-4">
                            <h1 className="text-center mb-5 fw-bold">Change Your Passcode</h1>
                             <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="oldPassword">
                                    <Form.Label>Current Passcode</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter current passcode"
                                        value={user.oldPassword}
                                        onChange={handleChange}
                                        required
                                        spellCheck="false"
                                        autoComplete="current-password"
                                        autoCapitalize="none"
                                    />
                                </Form.Group>
                                <Alert key="dark" variant="secondary" className="small" >
                                    <span>
                                        Passwords should be between 8 and 64 characters long, with at least one capital letter and one symbol.
                                    </span>
                                </Alert>
                                <Form.Group className="mb-3" controlId="choosePassword">
                                    <Form.Label>Choose Passcode</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Choose passcode"
                                        value={user.choosePassword}
                                        onChange={handleChange}
                                        isInvalid={user.choosePassword && !isValidPassword(user.choosePassword)}
                                        required
                                        spellCheck="false"
                                        autoComplete="current-password"
                                        autoCapitalize="none"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a valid passcode.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="confirmPassword">
                                    <Form.Label>Confirm Passcode</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm passcode"
                                        value={user.confirmPassword}
                                        onChange={handleChange}
                                        isInvalid={!(user.choosePassword === user.confirmPassword)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Passcodes do not match.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {update.error && (
                                    <Alert variant="danger">
                                        {update.error?.modelState?.custom[0] ??
                                            "Unable to change the passcode!"}
                                    </Alert>
                                )}
                                {update.status === "success" && (
                                    <Alert variant="success">Passcode changed successfully</Alert>
                                )}

                                <Button
                                    type="submit"
                                    className="btn-custom-primary w-100"
                                    disabled={user.choosePassword !== user.confirmPassword}
                                >
                                    Submit
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ChangePassword;