import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useAuth } from "../../providers/AuthContext";
import "./css/home.css";
import diningImage from "../../assets/img/dining.jpg";
import shoppingImage from "../../assets/img/shopping.jpg";
import gpImage from "../../assets/img/gp.jpg";
import retailerImage01 from "../../assets/img/retailer/argos.png";
import retailerImage02 from "../../assets/img/retailer/asos.png";
import retailerImage03 from "../../assets/img/retailer/bandq.png";
import retailerImage04 from "../../assets/img/retailer/currys-pc-world.png";
import retailerImage05 from "../../assets/img/retailer/xbox.png";
import retailerImage06 from "../../assets/img/retailer/halfords.png";
import retailerImage07 from "../../assets/img/retailer/marks-and-spencer.png";
import retailerImage08 from "../../assets/img/retailer/asda.png";
import retailerImage09 from "../../assets/img/retailer/starbucks.png";
import retailerImage10 from "../../assets/img/retailer/morrisons.png";
import retailerImage11 from "../../assets/img/retailer/tesco.png";
import retailerImage12 from "../../assets/img/retailer/virgin-wines.png";

const HomePage = () => {
    const navigate = useNavigate();
    const { IsAuthenticated } = useAuth();

    const _isAuthenticated = IsAuthenticated();

    return (
        <>
            <Helmet title="Save on all your travel needs throughout the year!" />
            <div className="container-fluid" id="home-leading-img">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="container">
                                <div className="h-100 col-12 col-lg-9 col-xl-6">
                                    <h1 id="introText" className="fw-bolder text-uppercase pb-3"><strong>The best way to enjoy your Christmas</strong></h1>
                                    <span className="h5">Only with the Christmas Savings Club</span>
                                </div>
                                
                                <Button variant="light" className="btn btn-custom-secondary btn-lg mt-4 px-5 text-uppercase btn-larger" onClick={() => navigate("/categories")}>{_isAuthenticated ? "Rewards" : "Login"}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid text-center pb-5" id="home-benefits">
                <div className="container">

                    <div className="row pb-5">

                        <div className="col-12 col-lg-10 offset-lg-1 pb-4">
                            <h1 className="color-primary display-5 fw-bold">SOME OF YOUR MEMBER BENEFITS</h1>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-md-4 mb-2 category-index-card">
                                    <div className="row no-gutters">
                                        <div className="col-12 category-index-img">
                                            <img className="img-fluid" src={diningImage} alt="Christmas Food &amp; Drink" />
                                        </div>
                                        <div className="col-12">
                                            <div className="category-index-title">
                                                <span>SAVINGS ON CHRISTMAS FOOD &amp; DRINK</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4 mb-2 category-index-card">
                                    <div className="row no-gutters">
                                        <div className="col-12 category-index-img">
                                            <img className="img-fluid" src={shoppingImage} alt="Christmas Shopping" />
                                        </div>
                                        <div className="col-12">
                                            <div className="category-index-title">
                                                <span>SAVINGS ON YOUR CHRISTMAS SHOPPING</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4 mb-2 category-index-card">
                                    <div className="row no-gutters">
                                        <div className="col-12 category-index-img">
                                            <img className="img-fluid" src={gpImage} alt="Private GP" />
                                        </div>
                                        <div className="col-12">
                                            <div className="category-index-title">
                                                <span>UNLIMITED PRIVATE GP / DOCTOR SERVICE</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pb-5 pt-5">

                        <div className="col-12 col-lg-10 offset-lg-1">
                            <h1 className="color-primary display-5 fw-bold pb-4">SOME OF OUR RETAILERS</h1>
                        </div>

                        <div className="row">
                            <img class="col-4 col-md-2 col-lg-1 mb-2 mb-lg-0" src={retailerImage01} alt="Argos" />
                            <img class="col-4 col-md-2 col-lg-1 mb-2 mb-lg-0" src={retailerImage02} alt="ASOS" />
                            <img class="col-4 col-md-2 col-lg-1 mb-2 mb-lg-0" src={retailerImage03} alt="B&amp;Q" />
                            <img class="col-4 col-md-2 col-lg-1 mb-2 mb-lg-0" src={retailerImage04} alt="Currys PC World" />
                            <img class="col-4 col-md-2 col-lg-1 mb-2 mb-lg-0" src={retailerImage05} alt="Xbox" />
                            <img class="col-4 col-md-2 col-lg-1 mb-2 mb-lg-0" src={retailerImage06} alt="Halfords" />
                            <img class="col-4 col-md-2 col-lg-1 mb-2 mb-lg-0" src={retailerImage07} alt="M&amp;S" />
                            <img class="col-4 col-md-2 col-lg-1 mb-2 mb-lg-0" src={retailerImage08} alt="B&amp;Q" />
                            <img class="col-4 col-md-2 col-lg-1 mb-2 mb-lg-0" src={retailerImage09} alt="Starbucks" />
                            <img class="col-4 col-md-2 col-lg-1 mb-2 mb-lg-0" src={retailerImage10} alt="Morrisons" />
                            <img class="col-4 col-md-2 col-lg-1 mb-2 mb-lg-0" src={retailerImage11} alt="Tesco" />
                            <img class="col-4 col-md-2 col-lg-1 mb-2 mb-lg-0" src={retailerImage12} alt="Virgin Atlantic" />
                        </div>

                        
                    </div>

                    <div className="row pb-5 pt-5">
                        <div className="col-12 col-lg-10 offset-lg-1">
                            <h1 className="color-primary display-5 fw-bold pb-4">WHAT IS THE CHRISTMAS SAVINGS CLUB?</h1>
                            <p>The Christmas Savings Club is a high-value membership product that will help you make the most of your Christmas season.</p>
                            <p>As a member of The Christmas Savings Club, you get access to our flagship reward, <strong>unlimited access to a private GP.</strong> Getting appointments is difficult at the best of times, but at Christmas, that only gets harder. Now, you can see a GP, via video call or telephone, and receive private prescriptions without even leaving your home. There's plenty of appointments to go around, so you'll never be waiting long, and are likely to see one, same-day.</p>
                            <p>Save on all the costs of Christmas, with discounts at your favourite online and high street retailers, as well as on food, drink, dining out, and more. There is no shortage of rewards for you. We're delighted to have you as a member!</p>
                        </div>
                    </div>

                    <div className="row pb-5 pt-5">
                        <div className="col-12 col-lg-10 offset-lg-1">
                            <h1 className="color-primary display-5 fw-bold pb-4">WIN BACK THE COST OF CHRISTMAS!</h1>
                            <p>That's right! Christmas is an expensive time of year and so we're covering the cost of Christmas for one of our lucky members.</p>
                            <p>Simply log into your account and you'll find instructions on entering. One lucky winner will win £1,000 cash in January 2025, to cover all their bills and spend over Christmas. Good luck!</p>
                        </div>
                    </div>


                </div>

                
                
            </div>

            

        </>
    );
}

export default HomePage;