import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import { Col, Container, Card, Row } from "react-bootstrap";
import { Navigate, generatePath, useNavigate } from "react-router-dom";

import { useAuth } from "../../providers/AuthContext";
import Loader from "../../components/Loader";
import Error from "../../components/Error";

const Categories = (props) => {

  const auth = useAuth();
  const navigate = useNavigate();
  const {
    isLoading,
    error,
    data: categories
  } = useQuery(["categories", auth]);

  const handleCardClick = (key) => {
    const category = encodeURI(key);
    const url = generatePath(`/categories/:category/rewards`, { category });
    navigate(url);
  };

  if (error === "login") {
    return <Navigate to={{ pathname: '/login', state: { from: props.location } }} replace />;
  }

  if (isLoading) return <Loader />;
  if (error) return <Error />;
  if (categories?.data.length <= 1) {
    return <Navigate to={{ pathname: "/rewards" }} replace />;
  }

  return (
    <>
      <Helmet title="Categories" />
      <Container fluid id="portalHeader" className="d-flex align-items-center">
        <Container>
          <h1 className="fw-bold display-4">Your Exclusive Christmas Rewards</h1>
          <h3>Indulge yourself with rewards, shopping discounts and more.</h3>
        </Container>
      </Container>

      <Container fluid="md" className="pb-4">
        <Row>
          {categories?.data.map((cat, i) => (
            <Col key={`cat_${i}`} sm={6} lg={4}>
              <Card className="category-index-card" id={cat.id} style={{ cursor: "pointer" }} onClick={() => handleCardClick(cat.id)}>
                <Card.Img variant="top" src={cat.images.thumbnailTabletDesktop} />
                <Card.Body className="p-0">
                  <Card.Title className="category-index-title fw-bold text-uppercase small mb-0">{cat.title}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default Categories;