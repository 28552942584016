import React, { useState } from 'react';
import { useMutation, useQuery } from "react-query";
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Alert, Button, Card } from "react-bootstrap";

import { BASEURL, POST } from '../../components/Constants';
import Loader from '../../components/Loader';

const ResetPassword = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const initalData = {
        cacheId: params.get("t"),
        newPassword: "",
        confirmPassword: ""
    };
    const [user, setUser] = useState(initalData);

    const {
        isLoading,
        error,
        data
    } = useQuery("reset-password", async ()=> {
        const response = await fetch(`${BASEURL}/users/reset-password/${params.get("t")}`, {
            headers: {
                "x-product-family": process.env.REACT_APP_FAMILY
            }
        });
        return await response.json();
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setUser((ps) => ({
            ...ps,
            [id]: value,
        }));
    };

    const submit = useMutation(
        (data) => POST("users/reset-password", undefined, data),
        {
            onSuccess: () => {
                setUser(initalData);
            },
        }
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        submit.mutate(user, { onSuccess: () => setUser(initalData) });
    };

    const isValidPassword = (password) => {
        // Regex pattern that checks:
        // - At least one uppercase letter
        // - At least one special character
        // - Length between 8 and 64 characters
        const pattern = /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,64}$/;

        return pattern.test(password);
    }

    if (isLoading) return <Loader />;

    return (
        <>
            <Helmet title='Reset password' />
            <Container style={{ paddingTop: 10 + 'rem', paddingBottom: 10 + 'rem' }}>
                <Row className="justify-content-md-center">
                    <Col md="10" lg="8">
                        <Card className="p-4">
                            <h1 className="text-center mb-5 fw-bold">Reset Password</h1>

                            {!isLoading && data && data.isSuccess &&
                                <Form onSubmit={handleSubmit}>

                                    <Alert key="dark" variant="secondary" className="small" >
                                        <span>
                                            Passwords should be between 8 and 64 characters long, with at least one capital letter and one symbol.
                                        </span>
                                    </Alert>

                                    <Form.Group className="mb-3" controlId="newPassword">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="New Password"
                                            value={user.newPassword}
                                            onChange={handleChange}
                                            isInvalid={user.newPassword && !isValidPassword(user.newPassword)}
                                            required
                                            spellCheck="false"
                                            autoComplete="current-password"
                                            autoCapitalize="none"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please choose a valid Password.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="confirmPassword">
                                        <Form.Label>Confirm Passcode</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Confirm passcode"
                                            value={user.confirmPassword}
                                            onChange={handleChange}
                                            isInvalid={!(user.newPassword === user.confirmPassword)}
                                            required
                                            spellCheck="false"
                                            autoComplete="current-password"
                                            autoCapitalize="none"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Passcodes do not match.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {submit.error && (
                                        <Alert variant="danger" m={2}>
                                            {submit.error?.modelState?.custom[0] ?? "Oops! It looks like the reset link is invalid or has expired. Please try again or request a new reset link."}
                                        </Alert>
                                    )}

                                    {submit.isLoading && (<Loader />)}

                                    {submit.isSuccess && (
                                        <Alert variant="success">
                                            <div> Password reset completed.</div>
                                            <Link to="/login">Try logging in.</Link>
                                        </Alert>
                                    )}
                                    <Button
                                        className="btn-custom-primary w-100"
                                        type="submit"
                                        disabled={submit.isLoading && user.choosePassword !== user.confirmPassword}>
                                        Submit
                                    </Button>


                                </Form>}

                            {((!isLoading && data && !data.isSuccess) || error) &&
                                <Alert variant="danger" m={2}>
                                    "Oops! It looks like the reset link is invalid or has expired. Please try again or request a new reset link."
                                </Alert>
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ResetPassword;