import React, { Component } from "react";
import ReactGA from 'react-ga4';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query"

import { DefaultQueryFn } from "./components/Constants";
import { ProvideAuth } from "./providers/AuthContext";
import ChangePassword from "./screens/ChangePassword";
import Login from "./screens/Login";
import Rewards from "./screens/Rewards";
import Layout from "./components/Layout";
import HomePage from "./screens/HomePage";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import RewardDetails from "./screens/RewardDetails";
import Profile from "./screens/Profile";
import RequireAuth from "./components/RequireAuth";
import ErrorPage from "./screens/Error";
import Categories from "./screens/Categories";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: DefaultQueryFn,
      refetchOnWindowFocus: false,
      retry: false
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: "/",
        element: <HomePage />
      },
      {
        index: true,
        path: "categories",
        element: <RequireAuth><Categories /></RequireAuth>
      },
      {
        index: true,
        path: "categories/:category/rewards",
        element: <RequireAuth><Rewards /></RequireAuth>
      },
      {
        path: "rewards",
        element: <RequireAuth><Rewards /></RequireAuth>
      },
      {
        index: true,
        path: "categories/:category/rewards/:reward",
        element: <RequireAuth><RewardDetails /></RequireAuth>
      },
      {
        path: "rewards/:reward",
        element: <RequireAuth><RewardDetails /></RequireAuth>
      },
      {
        path: "profile",
        element: <RequireAuth><Profile /></RequireAuth>
      },
      {
        path: "change-password",
        element: <RequireAuth><ChangePassword /></RequireAuth>
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      }
    ]
  },
]);

export default class App extends Component {
  render() {
    ReactGA.initialize('G-S8JWCF9VRF', {
      testMode: !process.env.REACT_APP_IS_PROD
    });
    return (
      <QueryClientProvider client={queryClient}>
        <ProvideAuth>
          <HelmetProvider>
            <Helmet
              titleTemplate="The Christmas Savings Club | %s"
              defaultTitle="The Christmas Savings Club"
            />
            <RouterProvider router={router} />
          </HelmetProvider>
        </ProvideAuth>
      </QueryClientProvider>
    );
  }
}
